import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Flex, Modal, Text } from "@mantine/core";
import { useAtom } from "jotai";
import React from "react";
import { modalconfirmAtom } from "~/stores";

const ModalConfirm = () => {
  const [modal, setModal] = useAtom(modalconfirmAtom);

  const closeModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
  };

  const {
    isOpen,
    onConfirm,
    onClose,
    illustration,
    title,
    description,
    cancelText,
    loading,
    confirmText,
    cancelIcon,
    confirmIcon,
    isDanger,
  } = modal;

  return (
    <Modal
      opened={isOpen}
      onClose={onClose ?? closeModal}
      withCloseButton={false}
      size="sm"
      radius="md"
      centered
      styles={{ body: { padding: 0 } }}
    >
      <Flex direction="column" justify="center" align="center" p="lg">
        {illustration ?? (
          <Icon
            icon="tabler:circle-x-filled"
            className="w-16 h-16 font-light text-ihcBlue-500"
          />
        )}
        <Text fw={500} mt="lg" size="md" c="dark.6">
          {title}
        </Text>
        {typeof description == "string" ? (
          <Text
            fw={300}
            mt="xxs"
            size="sm"
            maw={300}
            className="text-center"
            c="dark.3"
          >
            {description}
          </Text>
        ) : (
          description
        )}
      </Flex>
      <Flex
        direction={isDanger ? "row-reverse" : "row"}
        w="100%"
        justify="space-between"
        gap="md"
        align="center"
        p="lg"
        bg="ihcGrey.0"
      >
        <Button
          size="md"
          variant="default"
          aria-label="Cancel"
          bg="transparent"
          fullWidth
          onClick={onClose}
          leftSection={cancelIcon}
        >
          <Text fw={300}>{cancelText}</Text>
        </Button>

        <Button
          size="md"
          variant="filled"
          color={isDanger ? "red" : "ihcGreen"}
          aria-label="Delete"
          fullWidth
          onClick={() => {
            onConfirm();
          }}
          loading={loading}
          leftSection={confirmIcon}
        >
          <Text fw={300}>{confirmText}</Text>
        </Button>
      </Flex>
    </Modal>
  );
};

export default ModalConfirm;
